import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store/store";
import login from "./store/auth/login";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";
import vueDebounce from "vue-debounce";
import Snotify from "vue-snotify";
import "vue-snotify/styles/material.css";
Vue.use(Snotify);
Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(vueDebounce);
Vue.prototype.$log = console.log.bind(console);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
  beforeCreate() {
    Vue.$snotify = this.$snotify;
    if (login.getters.loggedIn() == false) {
      router.push("/login");
    }
  },
});
