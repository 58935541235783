// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox, 
// the second argument is the SVG content stripped of SVG tags
export const logo = ['556 134',`
<g transform="translate(0.000000,134.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M120 670 l0 -670 2640 0 2640 0 0 670 0 670 -2640 0 -2640 0 0 -670z
m1544 476 c64 -27 92 -109 41 -118 -22 -5 -25 -2 -25 23 0 45 -31 69 -89 69
-54 0 -74 -15 -69 -52 2 -18 23 -33 93 -68 99 -50 135 -88 135 -147 0 -84 -59
-133 -158 -133 -104 0 -152 34 -155 110 -2 28 2 34 26 41 26 7 27 6 27 -32 0
-52 35 -89 82 -89 66 0 102 55 60 93 -10 9 -42 27 -72 41 -74 33 -111 73 -117
129 -7 60 17 103 70 128 50 22 105 24 151 5z m2818 4 c68 -19 109 -112 54
-122 -21 -4 -25 0 -30 33 -7 46 -28 59 -89 59 -50 0 -70 -16 -65 -52 2 -18 23
-33 93 -68 101 -50 135 -88 135 -149 0 -48 -33 -99 -75 -117 -45 -19 -139 -18
-180 2 -36 17 -68 71 -63 106 2 14 13 24 31 29 26 7 27 6 27 -36 0 -35 5 -48
26 -64 49 -39 134 -17 134 33 0 30 -23 49 -91 80 -75 33 -119 84 -119 137 0
58 24 97 73 120 48 21 85 24 139 9z m630 -17 c52 -31 79 -95 79 -192 0 -67 -4
-92 -23 -129 -36 -72 -64 -87 -163 -87 -98 0 -125 14 -165 86 -21 37 -24 57
-24 129 0 87 20 148 58 179 52 43 176 50 238 14z m-1687 3 c40 -17 65 -60 65
-114 0 -74 -63 -122 -159 -122 l-50 0 -3 -87 -3 -88 -62 -3 -63 -3 0 216 0
215 120 0 c82 0 132 -5 155 -14z m235 -71 l0 -85 50 0 50 0 0 85 0 85 60 0 60
0 0 -215 0 -215 -60 0 -59 0 -3 106 -3 106 -47 2 -47 2 -3 -106 -3 -105 -60 0
-60 0 -3 213 -2 212 65 0 65 0 0 -85z m423 -13 c22 -51 43 -89 47 -85 4 4 22
47 40 96 28 76 36 87 56 87 13 0 24 -2 24 -5 0 -3 -22 -64 -50 -135 -45 -117
-50 -137 -50 -210 l0 -81 -62 3 -63 3 -5 95 c-5 82 -11 107 -44 180 -21 47
-44 100 -51 118 l-14 33 66 -3 66 -3 40 -93z m675 -114 l-3 -213 -60 0 -60 0
-3 213 -2 212 65 0 65 0 -2 -212z m-1698 152 c0 -47 2 -50 25 -50 18 0 25 -5
25 -20 0 -15 -7 -20 -25 -20 l-25 0 0 -120 0 -120 25 6 c31 8 33 -9 3 -30 -35
-25 -94 -20 -123 9 -24 23 -25 30 -25 140 0 108 -1 115 -20 115 -15 0 -20 -7
-20 -27 0 -42 -13 -55 -51 -51 -33 3 -34 4 -31 43 3 39 3 39 -15 22 -15 -15
-19 -38 -21 -143 l-4 -125 -56 3 -57 3 -3 158 -3 157 29 0 c16 0 44 3 61 6 27
6 31 4 31 -15 l0 -22 29 22 c29 21 81 21 81 0 0 -5 11 -4 25 2 16 8 25 20 25
35 0 17 12 28 53 46 28 14 55 25 60 25 4 1 7 -21 7 -49z m-2092 -172 l-2 -143
-3 138 -4 137 -124 0 -124 0 -4 -137 -3 -138 -2 143 -2 142 135 0 135 0 -2
-142z m952 112 c0 -20 0 -20 33 0 41 25 78 25 110 0 l26 -20 33 20 c43 26 92
26 118 0 18 -18 20 -33 20 -165 l0 -145 -55 0 -55 0 0 130 c0 109 -3 132 -16
137 -31 12 -39 -16 -42 -143 l-4 -125 -56 3 -57 3 -3 133 c-2 106 -5 132 -17
132 -24 0 -30 -22 -35 -145 l-5 -120 -57 -3 -58 -3 0 160 0 160 33 4 c77 9 87
8 87 -13z m622 10 c21 -6 42 -19 48 -30 5 -10 10 -69 10 -130 0 -103 2 -112
20 -117 11 -3 20 -11 20 -18 0 -27 -103 -34 -131 -9 -16 15 -20 15 -41 0 -12
-9 -42 -16 -66 -16 -57 0 -92 33 -92 88 0 47 32 80 102 106 54 20 71 37 66 67
-4 32 -31 45 -65 33 -23 -8 -28 -16 -28 -42 0 -27 -4 -32 -23 -32 -52 0 -38
78 18 97 44 15 114 16 162 3z m-1984 -277 l-148 -4 0 -119 0 -119 148 -3 147
-3 3 -142 3 -143 124 0 124 0 4 138 3 137 2 -142 2 -143 -135 0 -135 0 0 145
0 145 -150 0 -150 0 0 130 0 130 153 -2 152 -2 -147 -3z m640 0 c-43 -2 -113
-2 -155 0 -43 1 -8 3 77 3 85 0 120 -2 78 -3z m0 -250 c-43 -2 -113 -2 -155 0
-43 1 -8 3 77 3 85 0 120 -2 78 -3z m982 -243 c0 -5 -6 -10 -13 -10 -7 0 -17
-26 -25 -67 -16 -81 -40 -127 -70 -131 -26 -4 -37 17 -28 53 9 35 24 31 22 -6
-1 -41 18 -48 32 -12 11 27 42 144 42 157 0 3 -12 6 -26 6 -14 0 -23 4 -19 10
3 6 24 10 46 10 21 0 39 -4 39 -10z m825 0 c16 -7 13 -9 -16 -9 -32 -1 -37 -5
-47 -37 -10 -32 -9 -36 11 -44 21 -8 21 -9 -4 -9 -23 -1 -27 -7 -38 -57 -9
-40 -16 -53 -24 -45 -7 7 -7 22 2 52 10 33 10 43 1 49 -9 6 -6 10 8 14 11 4
22 14 25 24 3 9 8 25 12 35 6 16 4 18 -16 15 -15 -2 -26 -12 -30 -28 -6 -23
-7 -23 -8 -3 -2 42 69 67 124 43z m325 -5 c14 -17 10 -66 -10 -137 -8 -26 -11
-50 -7 -54 12 -12 45 26 64 72 10 24 21 44 26 44 10 0 9 -11 -9 -64 -19 -56
-13 -68 21 -36 39 37 68 106 62 146 -4 25 -2 34 6 31 40 -13 -19 -165 -77
-197 -25 -14 -28 -13 -40 3 -12 15 -15 15 -26 2 -15 -18 -47 -20 -53 -2 -2 6
5 43 15 80 11 37 18 78 16 90 -4 32 -32 28 -36 -5 -4 -30 -22 -39 -22 -11 0
20 28 53 45 53 7 0 18 -7 25 -15z m326 -64 c-9 -16 -21 -33 -26 -36 -6 -4 -2
8 10 27 11 18 20 40 20 48 -1 8 -11 -5 -24 -30 -13 -24 -22 -50 -19 -56 2 -7
0 -15 -6 -19 -17 -10 -13 -43 7 -47 16 -3 16 -4 0 -10 -10 -4 -23 -1 -31 6
-11 11 -16 11 -32 0 -37 -28 -81 -4 -67 36 8 26 45 60 64 60 37 0 12 -51 -32
-65 -24 -8 -26 -23 -5 -31 18 -7 65 21 65 39 0 18 49 119 68 141 16 18 17 18
20 -7 2 -14 -4 -39 -12 -56z m71 32 c-4 -20 -20 -53 -37 -74 -33 -42 -40 -72
-14 -67 17 3 28 18 38 52 5 14 9 16 20 7 10 -8 16 -9 21 -1 3 5 16 10 27 10
18 0 20 -4 15 -34 -3 -22 -1 -37 6 -40 7 -3 3 -5 -8 -3 -16 2 -19 8 -17 35 3
32 2 33 -18 22 -11 -6 -26 -22 -32 -36 -6 -14 -17 -23 -23 -20 -7 2 -18 0 -26
-4 -23 -15 -41 9 -33 44 11 52 64 146 82 146 2 0 2 -16 -1 -37z m-1309 -64 c8
-12 11 -32 7 -50 -4 -16 -2 -29 3 -29 12 0 37 30 47 55 7 19 7 19 14 2 6 -17
8 -17 20 -3 7 9 22 16 34 16 17 0 19 -5 14 -35 -8 -46 7 -44 38 5 28 44 46 56
70 46 21 -8 3 -43 -31 -58 -28 -13 -32 -38 -6 -39 10 0 12 -2 4 -6 -7 -3 -20
-1 -30 3 -9 4 -28 8 -42 8 -21 1 -25 5 -22 29 4 35 -20 36 -46 0 -13 -18 -30
-27 -56 -30 l-38 -4 7 41 c4 26 3 40 -4 40 -6 0 -11 7 -11 15 0 21 13 19 28
-6z m726 -53 c-7 -30 1 -33 30 -8 l21 17 -19 -23 c-12 -13 -28 -22 -40 -20
-22 4 -22 5 -11 51 9 34 -8 36 -23 2 -7 -16 -20 -25 -34 -25 -12 0 -32 -9 -44
-21 -44 -42 -63 18 -21 67 30 35 63 28 53 -12 -10 -37 24 -28 44 12 l15 31 18
-23 c12 -15 16 -31 11 -48z m840 28 c6 -50 -4 -74 -33 -74 -9 0 -22 5 -29 12
-9 9 -15 9 -24 0 -16 -16 -39 -15 -62 3 -18 13 -18 15 3 49 24 38 57 54 74 37
12 -12 -14 -47 -44 -56 -23 -8 -25 -23 -3 -32 10 -4 27 5 46 26 34 37 34 36
34 12 0 -11 -4 -17 -9 -14 -5 3 -8 0 -5 -7 2 -8 9 -15 16 -18 15 -5 28 46 14
60 -10 10 -5 48 8 48 4 0 10 -21 14 -46z m93 -61 c-14 -19 -51 -16 -64 6 -10
16 -8 22 9 37 11 11 25 30 31 44 10 24 11 22 22 -25 8 -33 9 -54 2 -62z
m-1841 83 c3 -8 1 -23 -5 -33 -9 -15 -10 -13 -5 10 4 17 2 27 -6 27 -6 0 -10
-11 -8 -27 4 -38 35 -43 48 -9 5 14 14 26 20 26 7 0 8 -6 0 -19 -13 -25 -13
-51 0 -51 6 0 20 16 32 35 24 42 35 39 20 -4 -9 -25 -8 -31 3 -31 8 0 21 10
29 22 9 12 16 17 16 11 0 -6 -12 -21 -26 -32 -21 -16 -30 -18 -40 -10 -11 9
-18 9 -28 0 -10 -9 -16 -8 -26 4 -16 19 -39 19 -64 0 -14 -11 -23 -12 -33 -4
-21 18 -15 38 19 70 36 33 46 35 54 15z m481 -8 c-17 -47 -18 -58 -3 -58 9 0
23 14 31 31 20 38 31 31 17 -10 -11 -30 -6 -39 14 -23 5 4 0 -2 -11 -13 -12
-11 -29 -32 -38 -47 -18 -29 -47 -38 -47 -14 0 7 8 20 18 27 14 11 15 11 5 -1
-15 -16 -17 -34 -4 -25 7 4 41 62 41 69 0 6 -69 -2 -80 -8 -8 -5 -5 2 6 15 12
13 25 34 29 47 3 12 11 22 17 22 5 0 8 -6 5 -12z"/>
<path d="M4965 1096 c-24 -36 -18 -307 7 -332 25 -25 64 -11 77 27 15 45 14
267 -2 299 -18 36 -61 39 -82 6z"/>
<path d="M3280 1025 c0 -83 0 -85 24 -85 47 0 74 50 62 115 -7 36 -30 55 -65
55 -20 0 -21 -6 -21 -85z"/>
<path d="M2448 881 c-31 -36 -36 -77 -14 -107 14 -17 16 -18 31 -3 12 12 15
31 13 79 l-3 64 -27 -33z"/>
<path d="M3527 152 c-10 -10 -17 -22 -17 -26 0 -12 37 13 44 29 8 21 -7 19
-27 -3z"/>
<path d="M3695 210 c-9 -17 -15 -36 -13 -42 2 -6 12 6 22 26 22 46 14 60 -9
16z"/>
<path d="M2633 158 c-6 -7 -14 -20 -19 -28 -7 -13 -5 -13 16 1 25 17 38 39 22
39 -5 0 -14 -6 -19 -12z"/>
<path d="M3019 154 c-25 -29 -34 -64 -15 -64 19 0 38 34 34 62 -3 20 -3 20
-19 2z"/>
<path d="M3866 148 c-9 -12 -13 -24 -11 -27 6 -5 45 29 45 41 0 15 -19 8 -34
-14z"/>
<path d="M4042 138 c-8 -8 -9 -15 0 -25 8 -11 7 -12 -6 -7 -12 5 -15 2 -10
-10 12 -30 34 -18 34 19 0 39 -1 40 -18 23z"/>
<path d="M2177 126 c-10 -27 -8 -36 7 -36 22 0 27 15 12 35 -12 17 -13 17 -19
1z"/>
</g>
`]
