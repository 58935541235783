import router from "@/router";
import { http } from "../../config/http";

import _ from "lodash";

const namespaced = true;


const getDefaultState = () => {
    return {
        dateTime: []
         
    };
};

const state = getDefaultState();


const mutations = {
    getDateTimeData(state, data) {
        console.log(data);
        if (data != null) {
            state.dateTime = data;
        } else {
            state.dateTime = null;
        }
    } 
};

const actions = {

    async getDateTime({ commit }, data) {
        await http
          .get("settings/common/date-time", data)
          .then((response) => {
            if (response) {
              commit("getDateTimeData", response.data.date);
            } else {
              commit("getDateTimeData", null);
            }
          })
          .catch((error) => {
            console.log(error);
          });

    },

};

export default {
    namespaced,
    state,
    actions,
    mutations
};
