import router from "@/router";
import { http } from "../../config/http";

import _ from "lodash";

const namespaced = true;


const getDefaultState = () => {
    return {
        customerInfo: [],
        customerNameList : [],
        customerHistory : []
    };
};

const state = getDefaultState();
 
const mutations = {
    getCustomerInfoData(state, data) {
        console.log(data);
        if (data != null) {
            state.customerInfo = data;
        } else {
            state.customerInfo = null;
        }
    },

    getCustomerSearchByNameData(state, data) {
        console.log(data);
        if (data != null) {
            state.customerNameList = data;
        } else {
            state.customerNameList = null;
        }
    },

    getCustomerHistoryData(state, data) {
        console.log(data);
        if (data != null) {
            state.customerHistory = data;
        } else {
            state.customerHistory = null;
        }
    },
   
};

const actions = {

    async getCustomerInfo({ commit }, data) {
        await http
            .post("customer/find_customer", data)
            .then(response => {
                if (response) {
                    commit("getCustomerInfoData", response.data.data);
                } else {
                    commit("getCustomerInfoData", null);
                }
            })
            .catch(error => {
             //   console.log(error);
            });
    },

    async getCustomerSearchByName({ commit }, data) {
        await http
            .post("customer/find_customer", data)
            .then(response => {
                if (response) {
                    commit("getCustomerSearchByNameData", response.data.data);
                } else {
                    commit("getCustomerSearchByNameData", null);
                }
            })
            .catch(error => {
              //  console.log(error);
            });
    },
    async saveCustomerInfo({ commit }, data) {
        await http
            .post("customer/store_customer", data)
            .then(response => {
                if (response) {
                    commit("getCustomerInfoData", response.data.data);
                } else {
                    commit("getCustomerInfoData", null);
                }
            })
            .catch(error => {
                console.log(error);
            });
    },
    async updateCustomerInfo({ commit }, data){
        await http
        .post("customer/update_customer/"+data.id, data)
        .then(response => {
            if (response) {
                commit("getCustomerInfoData", response.data.data);
            } else {
                commit("getCustomerInfoData", null);
            }
        })
        .catch(error => {
            console.log(error);
        });
    },
    async getCustomerHistory({ commit }, id) {
        await http
            .get("customer/history/"+id)
            .then(response => {
                if (response) {
                    commit("getCustomerHistoryData", response.data.data);
                } else {
                    commit("getCustomerHistoryData", null);
                }
            })
            .catch(error => {
                console.log(error);
            });
    },
};

export default {
    namespaced,
    state,
    actions,
    mutations
};
