import router from "@/router";
import { http } from "../../config/http";

import _ from "lodash";

const namespaced = true;

const getDefaultState = () => {
  return {
    collectionInfo: [],
    totalCollectionAmount: [],
    statusChange: [],
  };
};

const state = getDefaultState();

const mutations = {
  //   getinvoiceInfoData(state, data) {
  //     console.log(data);
  //     if (data != null) {
  //       state.invoiceInfo = data;
  //     } else {
  //       state.invoiceInfo = null;
  //     }
  //   },
  totalCollectionAmountData(state, data) {
    console.log(data);
    if (data != null) {
      state.totalCollectionAmount = data;
    } else {
      state.totalCollectionAmount = null;
    }
  },
  postStatusChangeData(state, data) {
    console.log(data);
    if (data != null) {
      state.statusChange = data;
    } else {
      state.statusChange = null;
    }
  },
  cashCollectionDetailData(state, data) {
    console.log(data);
    if (data != null) {
      state.collectionInfo = data;
    } else {
      state.collectionInfo = null;
    }
  },
};

const actions = {
  //   async getinvoiceInfo({ commit }, data) {
  //     await http
  //       .post("invoice/store_invoice", data)
  //       .then((response) => {
  //         if (response) {
  //           commit("getinvoiceInfoData", response.data.data);
  //         } else {
  //           commit("getinvoiceInfoData", null);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   },
  async getTotalCollectionAmount({ commit }, data) {
    await http
      .get("cash_collect/collection/collection", { params: data })
      .then((response) => {
        if (response) {
          commit("totalCollectionAmountData", response.data);
        } else {
          commit("totalCollectionAmountData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async getCashCollectionDetail({ commit }, data) {
    await http
      .get("cash_collect/collection/get_by_id/" + data)
      .then((response) => {
        if (response) {
          commit("cashCollectionDetailData", response.data.data);
        } else {
          commit("cashCollectionDetailData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async postStatusChange({ commit }, data) {
    await http
      .post("cash_collect/collection/change-status/" + data.id, data)
      .then((response) => {
        if (response) {
          commit("postStatusChangeData", response.data.meta);
        } else {
          commit("postStatusChangeData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
};
