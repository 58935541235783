import router from "@/router";
import { http } from "@/config/http";
import _ from "lodash";

const namespaced = true;

const getDefaultState = () => {
  return {
    courierData: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      password: "",
    },
  };
};

const state = getDefaultState();

const getters = {};

const mutations = {
  updateUserData(state, data) {
    if (data != null) {
      //console.log(data.data);
      state.courierData = data.data;
      state.courierData.status = data.meta.status;
      state.courierData.status_message = data.meta.status_message;
    }
  },
};

const actions = {
  async createUser({ commit }, data) {
    await http
      .post("courier/crete", data)
      .then((response) => {
        commit("updateUserData", response.data);
      })
      .catch((error) => {
        commit("updateUserData", null);
      });
  },

  async updateUser({ commit }, data, userId = null) {
    await http
      .post("courier/edit/" + data.id + "", data)
      .then((response) => {
        commit("updateUserData", response.data);
      })
      .catch((error) => {
        commit("updateUserData", null);
      });
  },
  async getUserData({ commit }, userId) {
    await http
      .get("courier/show/" + userId + "", {}, {})
      .then((response) => {
        if (response) {
          commit("updateUserData", response.data);
        } else {
          commit("updateUserData", null);
        }
      })
      .catch((error) => {
        commit("updateUserData", null);
      });
  },

  async changePassword({ commit }, data) {
    await http
      .post("courier/change-password", data)
      .then((response) => {
        commit("updateUserData", response.data);
      })
      .catch((error) => {
        commit("updateUserData", null);
      });
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
