import router from "@/router";
import { http } from "../../config/http";

import _ from "lodash";

const namespaced = true;

const getDefaultState = () => {
  return {
    cityInfo: [],
    cityDetails: [],
  };
};

const state = getDefaultState();

const mutations = {
  saveCityData(state, data) {
    if (data != null) {
      state.cityInfo = data;
    } else {
      state.cityInfo = null;
    }
  },
  getCityDetailsData(state, data) {
    if (data != null) {
      state.cityDetails = data.data;
    } else {
      state.cityDetails = null;
    }
  },
};

const actions = {
  async getCityDropDown({ commit }) {
    await http
      .get("/settings/city/dropDown")
      .then((response) => {
        if (response) {
          commit("saveCityData", response.data.data);
        } else {
          commit("saveCityData", null);
        }
      })
      .catch((error) => {
        //   console.log(error);
      });
  },
  async getCity({ commit }, id) {
    await http
      .get("/settings/city/show/" + id)
      .then((response) => {
        if (response) {
          commit("getCityDetailsData", response.data);
        } else {
          commit("getCityDetailsData", null);
        }
      })
      .catch((error) => {
        commit("getCityDetailsData", null);
      });
  },
  async saveCity({ commit }, data) {
    await http
      .post("/settings/city/create", data)
      .then((response) => {
        if (response) {
          commit("saveCityData", response.data.data);
        } else {
          commit("saveCityData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async updateCity({ commit }, data) {
    await http
      .post("/settings/city/update/" + data.id, data)
      .then((response) => {
        if (response) {
          commit("saveCityData", response.data.data);
        } else {
          commit("saveCityData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
};
