import router from "@/router";
import { http } from "../../config/http";

import _ from "lodash";

const namespaced = true;

const getDefaultState = () => {
  return {
    courierList: [],
  };
};

const state = getDefaultState();

const mutations = {
  getCourierListData(state, data) {
    if (data != null) {
      state.courierList = data;
    } else {
      state.courierList = null;
    }
  },
};

const actions = {
  async getCourierList({ commit }) {
    await http
      .get("cash_collect/settings/courier_list")
      .then((response) => {
        if (response) {
          commit("getCourierListData", response.data);
        } else {
          commit("getCourierListData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
};
