import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

const Colors = () => import("@/views/theme/Colors");
const Typography = () => import("@/views/theme/Typography");

const Charts = () => import("@/views/charts/Charts");
const Widgets = () => import("@/views/widgets/Widgets");

// Views - Components
const Cards = () => import("@/views/base/Cards");
const Forms = () => import("@/views/base/Forms");
const Switches = () => import("@/views/base/Switches");
const Tables = () => import("@/views/base/Tables");
const Tabs = () => import("@/views/base/Tabs");
const Breadcrumbs = () => import("@/views/base/Breadcrumbs");
const Carousels = () => import("@/views/base/Carousels");
const Collapses = () => import("@/views/base/Collapses");
const Jumbotrons = () => import("@/views/base/Jumbotrons");
const ListGroups = () => import("@/views/base/ListGroups");
const Navs = () => import("@/views/base/Navs");
const Navbars = () => import("@/views/base/Navbars");
const Paginations = () => import("@/views/base/Paginations");
const Popovers = () => import("@/views/base/Popovers");
const ProgressBars = () => import("@/views/base/ProgressBars");
const Tooltips = () => import("@/views/base/Tooltips");

// Views - Buttons
const StandardButtons = () => import("@/views/buttons/StandardButtons");
const ButtonGroups = () => import("@/views/buttons/ButtonGroups");
const Dropdowns = () => import("@/views/buttons/Dropdowns");
const BrandButtons = () => import("@/views/buttons/BrandButtons");

// Views - Icons
const CoreUIIcons = () => import("@/views/icons/CoreUIIcons");
const Brands = () => import("@/views/icons/Brands");
const Flags = () => import("@/views/icons/Flags");

// Views - Notifications
const Alerts = () => import("@/views/notifications/Alerts");
const Badges = () => import("@/views/notifications/Badges");
const Modals = () => import("@/views/notifications/Modals");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");

const Login = () => import("@/views/pages/Login");
const InvoiceAdd = () => import("@/views/pages/invoice/AddInvoice");
const InvoiceList = () => import("@/views/pages/invoice/ListInvoice");
// const DailyReport = () => import("@/views/pages/report/DailyReport");
// const MonthlyReport = () => import("@/views/pages/report/MonthlyReport");
// const WeeklyReport = () => import("@/views/pages/report/WeeklyReport");
const CustomerHistory = () => import("@/views/pages/customer/CustomerHistory");
const CustomerList = () => import("@/views/pages/customer/CustomerList");
const AddCustomer = () => import("@/views/pages/customer/AddCustomer");
const Logout = () => import("@/components/auth/Logout");
const Register = () => import("@/views/pages/Register");


const CityAdd = () => import("@/views/pages/settings/city/AddCity");
const CityList = () => import("@/views/pages/settings/city/ListCity");

const BranchAdd = () => import("@/views/pages/settings/branch/AddBranch");
const BranchList = () => import("@/views/pages/settings/branch/ListBranch");

const NewsAdd = () => import("@/views/pages/settings/news/AddNews");
const NewsList = () => import("@/views/pages/settings/news/ListNews");

// Courier Cash Collection
const CollectionReport = () =>
  import("@/views/courierCashCollection/collectionReport");
  const CollectionCreditReport = () =>
    import("@/views/courierCashCollection/collectionCreditReport");
  const SupervisourBatch = () =>
    import("@/views/courierCashCollection/supervisourBatch");
  const ViewBatchInfo = () =>
    import("@/views/courierCashCollection/viewBatchInfo");
  const CollectionSummaryReport = () =>
    import("@/views/courierCashCollection/collectionSummaryReport");
  const CollectionInvoiceReport = () =>
    import("@/views/courierCashCollection/collectionInvoiceReport");

  // Users
  const Users = () => import("@/views/users/Users");
  const User = () => import("@/views/users/User");

  // Authorization
  const UserList = () => import("@/views/authorization/user/ListUser");
  const UserAdd = () => import("@/views/authorization/user/AddUser");
  const RoleList = () => import("@/views/authorization/userRole/ListRole");
  const RoleAdd = () => import("@/views/authorization/userRole/AddRole");
  const ChangePassword = () =>
    import("@/views/authorization/user/ChangePassword");
  const CourierList = () => import("@/views/authorization/courier/CourierList");
  const CourierAdd = () => import("@/views/authorization/courier/CourierAdd");

  // Rate Calculator
  const RateUserList = () => import("@/views/rateCalculator/user/ListUser");
  const RateUserAdd = () => import("@/views/rateCalculator/user/AddUser");

  const ListPayment = () =>
    import("@/views/rateCalculator/payment/ListPayment");
  const ListPickup = () => import("@/views/rateCalculator/pickup/ListPickup");
  const ListContact = () =>
    import("@/views/rateCalculator/contact/ListContact");

  // Customer Billing Portal 
  const CBPCustomerList = () => import("@/views/cbp/customer/ListCustomer");
  const CBPPaymentReport = () => import("@/views/cbp/payment/ListPayment");
  const CBPOpenInvoice = () => import("@/views/cbp/customer/OpenInvoice");
  const CBPPaidInvoice = () => import("@/views/cbp/customer/PaidInvoice");


  Vue.use(Router);

  export default new Router({
    mode: "hash", // https://router.vuejs.org/api/#mode
    linkActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes(),
  });

  function configRoutes() {
    return [
      {
        path: "/",
        redirect: "/dashboard",
        name: "Home",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "login",
            name: "Login",
            component: Login,
          },
          {
            path: "logout",
            name: "Logout",
            component: Logout,
          },
        ],
      },

      {
        path: "/",
        redirect: "/dashboard",
        name: "Home",
        component: TheContainer,
        children: [
          {
            path: "dashboard",
            name: "Dashboard",
            component: Dashboard,
          },
          {
            path: "invoice",
            redirect: "/theme/colors",
            name: "Invoice",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "invoice-add",
                name: "Add Invoice",
                component: InvoiceAdd,
              },
              {
                path: "invoice-list",
                name: "Invoice List",
                component: InvoiceList,
              },
              {
                path: "customer-list",
                name: "Patient List",
                component: CustomerList,
              },
            ],
          },
          {
            path: "customer",
            redirect: "/theme/colors",
            name: "Customer",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "history/:customer_id",
                name: "CustomerHistory",
                component: CustomerHistory,
              },
              {
                path: "history/:customer_id",
                name: "CustomerHistory",
                component: CustomerHistory,
              },
              {
                path: "add-customer",
                name: "AddCustomer",
                component: AddCustomer,
              },
              {
                path: "edit-customer/:customer_id",
                name: "EditCustomer",
                component: AddCustomer,
              },
            ],
          },
          {
            path: "courier_cash_collection",
            name: "Courier Cash Collection",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "collection-report",
                name: "CollectionReport",
                component: CollectionReport,
              },
              {
                path: "collection-credit-report",
                name: "CollectionCreditReport",
                component: CollectionCreditReport,
              },
              {
                path: "collection-invoice-report",
                name: "CollectionInvoiceReport",
                component: CollectionInvoiceReport,
              },
              {
                path: "supervisor-batch",
                name: "Supervisor Batch",
                component: SupervisourBatch,
              },
              {
                path: "view-batch/:batch_id",
                name: "View Batch",
                component: ViewBatchInfo,
              },
              {
                path: "collection-summery-report",
                name: "Collection Summery Report",
                component: CollectionSummaryReport,
              },
            ],
          },
          {
            path: "charts",
            name: "Charts",
            component: Charts,
          },
          {
            path: "widgets",
            name: "Widgets",
            component: Widgets,
          },
          {
            path: "users",
            meta: {
              label: "Users",
            },
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "",
                name: "Users",
                component: Users,
              },
              {
                path: ":id",
                meta: {
                  label: "User Details",
                },
                name: "User",
                component: User,
              },
            ],
          },
          {
            path: "authorization",
            name: "Authorization",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "user",
                name: "UserAuth",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "user-list",
                    name: "UserList",
                    component: UserList,
                  },
                  {
                    path: "user-add",
                    name: "UserAdd",
                    component: UserAdd,
                  },
                  {
                    path: "user-add/:user_id",
                    name: "UserEdit",
                    component: UserAdd,
                  },
                  {
                    path: "change-password",
                    name: "ChangePassword",
                    component: ChangePassword,
                  },
                ],
              },
              {
                path: "role",
                name: "UserRole",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "role-list",
                    name: "RoleList",
                    component: RoleList,
                  },
                  {
                    path: "role-add",
                    name: "RoleAdd",
                    component: RoleAdd,
                  },
                  {
                    path: "role-add/:role_id",
                    name: "RoleEdit",
                    component: RoleAdd,
                  },
                ],
              },
              {
                path: "courier",
                name: "UserAuth",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "courier-list",
                    name: "CourierList",
                    component: CourierList,
                  },
                  {
                    path: "courier-add",
                    name: "CourierAdd",
                    component: CourierAdd,
                  },
                  {
                    path: "courier-add/:courier_id",
                    name: "CourierEdit",
                    component: CourierAdd,
                  },
                ],
              },
            ],
          },
          {
            path: "settings",
            name: "Settings",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "city",
                name: "City",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "city-list",
                    name: "CityList",
                    component: CityList,
                  },
                  {
                    path: "city-add",
                    name: "CityAdd",
                    component: CityAdd,
                  },
                  {
                    path: "city-edit/:city_id",
                    name: "CityEdit",
                    component: CityAdd,
                  },
                ],
              },
              {
                path: "branch",
                name: "Branch",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "branch-list",
                    name: "BranchList",
                    component: BranchList,
                  },
                  {
                    path: "branch-add",
                    name: "BranchAdd",
                    component: BranchAdd,
                  },
                  {
                    path: "branch-edit/:branch_id",
                    name: "BranchEdit",
                    component: BranchAdd,
                  },
                ],
              },
              {
                path: "news",
                name: "News",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "news-list",
                    name: "NewsList",
                    component: NewsList,
                  },
                  {
                    path: "news-add",
                    name: "NewsAdd",
                    component: NewsAdd,
                  },
                  {
                    path: "news-edit/:news_id",
                    name: "NewsEdit",
                    component: NewsAdd,
                  },
                ],
              },
            ],
          },
          {
            path: "rateCalculator",
            name: "RateCalculator",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "user",
                name: "RateUser",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "user-list",
                    name: "RateUserList",
                    component: RateUserList,
                  },
                  {
                    path: "user-add",
                    name: "RateUserAdd",
                    component: RateUserAdd,
                  },
                  {
                    path: "user-add/:user_id",
                    name: "RateUserEdit",
                    component: RateUserAdd,
                  },
                ],
              },
              {
                path: "payment",
                name: "Payment",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "payment-list",
                    name: "ListPayment",
                    component: ListPayment,
                  },
                ],
              },
              {
                path: "contact",
                name: "Contact",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "contact-list",
                    name: "ListContact",
                    component: ListContact,
                  },
                ],
              },
              {
                path: "pickup",
                name: "Pickup",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "pickup-list",
                    name: "ListPickup",
                    component: ListPickup,
                  },
                ],
              },
            ],
          },
          {
            path: "customer-billing-portal",
            name: "CustomerBillingPortal",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "customer",
                name: "Customer",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "customer-list",
                    name: "CustomerLists",
                    component: CBPCustomerList,
                  },
                  {
                    path: "open-invoice::icpc",
                    name: "CBPOpenInvoice",
                    component: CBPOpenInvoice,
                  },
                  {
                    path: "paid-invoice::icpc",
                    name: "CBPPaidInvoice",
                    component: CBPPaidInvoice,
                  },
                ],
              },
              {
                path: "payment",
                name: "Payment",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "payment-report",
                    name: "paymentReport",
                    component: CBPPaymentReport,
                  },
                ],
              },
              // {
              //   path: "contact",
              //   name: "Contact",
              //   component: {
              //     render(c) {
              //       return c("router-view");
              //     },
              //   },
              //   children: [
              //     {
              //       path: "contact-list",
              //       name: "ListContact",
              //       component: ListContact,
              //     },
              //   ],
              // },
              // {
              //   path: "pickup",
              //   name: "Pickup",
              //   component: {
              //     render(c) {
              //       return c("router-view");
              //     },
              //   },
              //   children: [
              //     {
              //       path: "pickup-list",
              //       name: "ListPickup",
              //       component: ListPickup,
              //     },
              //   ],
              // },
            ],
          },
          {
            path: "base",
            redirect: "/base/cards",
            name: "Base",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "cards",
                name: "Cards",
                component: Cards,
              },
              {
                path: "forms",
                name: "Forms",
                component: Forms,
              },
              {
                path: "switches",
                name: "Switches",
                component: Switches,
              },
              {
                path: "tables",
                name: "Tables",
                component: Tables,
              },
              {
                path: "tabs",
                name: "Tabs",
                component: Tabs,
              },
              {
                path: "breadcrumbs",
                name: "Breadcrumbs",
                component: Breadcrumbs,
              },
              {
                path: "carousels",
                name: "Carousels",
                component: Carousels,
              },
              {
                path: "collapses",
                name: "Collapses",
                component: Collapses,
              },
              {
                path: "jumbotrons",
                name: "Jumbotrons",
                component: Jumbotrons,
              },
              {
                path: "list-groups",
                name: "List Groups",
                component: ListGroups,
              },
              {
                path: "navs",
                name: "Navs",
                component: Navs,
              },
              {
                path: "navbars",
                name: "Navbars",
                component: Navbars,
              },
              {
                path: "paginations",
                name: "Paginations",
                component: Paginations,
              },
              {
                path: "popovers",
                name: "Popovers",
                component: Popovers,
              },
              {
                path: "progress-bars",
                name: "Progress Bars",
                component: ProgressBars,
              },
              {
                path: "tooltips",
                name: "Tooltips",
                component: Tooltips,
              },
            ],
          },
          {
            path: "buttons",
            redirect: "/buttons/standard-buttons",
            name: "Buttons",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "standard-buttons",
                name: "Standard Buttons",
                component: StandardButtons,
              },
              {
                path: "button-groups",
                name: "Button Groups",
                component: ButtonGroups,
              },
              {
                path: "dropdowns",
                name: "Dropdowns",
                component: Dropdowns,
              },
              {
                path: "brand-buttons",
                name: "Brand Buttons",
                component: BrandButtons,
              },
            ],
          },
          {
            path: "icons",
            redirect: "/icons/coreui-icons",
            name: "CoreUI Icons",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "coreui-icons",
                name: "Icons library",
                component: CoreUIIcons,
              },
              {
                path: "brands",
                name: "Brands",
                component: Brands,
              },
              {
                path: "flags",
                name: "Flags",
                component: Flags,
              },
            ],
          },
          {
            path: "notifications",
            redirect: "/notifications/alerts",
            name: "Notifications",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "alerts",
                name: "Alerts",
                component: Alerts,
              },
              {
                path: "badges",
                name: "Badges",
                component: Badges,
              },
              {
                path: "modals",
                name: "Modals",
                component: Modals,
              },
            ],
          },
        ],
      },
      {
        path: "/pages",
        redirect: "/pages/404",
        name: "Pages",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "404",
            name: "Page404",
            component: Page404,
          },
          {
            path: "500",
            name: "Page500",
            component: Page500,
          },
          {
            path: "login",
            name: "Login",
            component: Login,
          },
          {
            path: "register",
            name: "Register",
            component: Register,
          },
        ],
      },
    ];
  }


 