import router from "@/router";
import { http } from "../../config/http";

import _ from "lodash";

const namespaced = true;

const getDefaultState = () => {
  return {
    batchInfo: [],
    batchAll: [],
    approveBatch: [],
  };
};

const state = getDefaultState();

const mutations = {
  batchInfoData(state, data) {
    if (data != null) {
      state.batchInfo = data;
    } else {
      state.batchInfo = null;
    }
  },

  approveBatchData(state, data) {
    if (data != null) {
      state.approveBatch = data;
    } else {
      state.approveBatch = null;
    }
  },

  batchByIdData(state, data) {
    if (data != null) {
      state.batchAll = data;
    } else {
      state.batchAll = null;
    }
  },
};

const actions = {
  async postBatchInfo({ commit }, data) {
    await http
      .post("cash_collect/batch/generate", data)
      .then((response) => {
        if (response) {
          commit("batchInfoData", response.data.data);
        } else {
          commit("batchInfoData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async postApproveBatch({ commit }, data) {
    await http
      .post("cash_collect/batch/approve/" + data)
      .then((response) => {
        if (response) {
          commit("approveBatchData", response.data.meta);
        } else {
          commit("approveBatchData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async getBatchById({ commit }, id) {
    await http
      .get("cash_collect/batch/get_batch/" + id)
      .then((response) => {
        if (response) {
          commit("batchByIdData", response.data.data);
        } else {
          commit("batchByIdData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
};
