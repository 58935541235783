import router from "@/router";
import { http } from "../../config/http";

import _ from "lodash";

const namespaced = true;


const getDefaultState = () => {
    return {
        serviceInfo: [],
        physioInfo: []
    };
};

const state = getDefaultState();


const mutations = {
    getServiceInfoData(state, data) {
        console.log(data);
        if (data != null) {
            state.serviceInfo = data;
        } else {
            state.serviceInfo = null;
        }
    },
    getPhysioInfoData(state, data) {
        console.log(data);
        if (data != null) {
            state.physioInfo = data;
        } else {
            state.physioInfo = null;
        }
    }
};

const actions = {

    async getServiceInfo({ commit }, data) {
        await http
            .get("service/service_list", data)
            .then(response => {
                if (response) {
                    commit("getServiceInfoData", response.data.data);
                } else {
                    commit("getServiceInfoData", null);
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

    async getPhysioInfo({ commit }, data) {
        await http
            .get("service/physio_list", data)
            .then(response => {
                if (response) {
                    commit("getPhysioInfoData", response.data.data);
                } else {
                    commit("getPhysioInfoData", null);
                }
            })
            .catch(error => {
                console.log(error);
            });

    },

};

export default {
    namespaced,
    state,
    actions,
    mutations
};
