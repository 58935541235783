import Vue from "vue";
import Vuex from "vuex";
import login from "@/store/auth/login";
import user from "@/store/auth/user";
import courier from "@/store/auth/courier";
import role from "@/store/auth/role";
import customer from "@/store/customer/customer";
//import invoice from "@/store/invoice/invoice";
import service from "@/store/service/service";
import common from "@/store/settings/common";
import news from "@/store/settings/news";
import branch from "@/store/settings/branch";
import city from "@/store/settings/city";

// courier cash collection
import courierCashCommon from "@/store/courierCashCollection/common";
import courierCashCollection from "@/store/courierCashCollection/collection";
import courierCashBatch from "@/store/courierCashCollection/batch";

// Rate Calculator
import Rateuser from "@/store/rateCalculator/user";

Vue.use(Vuex);

const namespaced = true;

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
};

export default new Vuex.Store({
  namespaced,
  state,
  mutations,
  modules: {
    login,
    user,
    role,
    customer,
    courier,
    service,
    common,
    news,
    branch,
    city,
    courierCashCommon,
    courierCashCollection,
    courierCashBatch,
    Rateuser,
  },
});
