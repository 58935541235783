import router from "@/router";
import { http } from "../../config/http";

import _ from "lodash";

const namespaced = true;

const getDefaultState = () => {
  return {
    newsInfo: [],
    newsDetails: [],
  };
};

const state = getDefaultState();

const mutations = {
  saveNewsData(state, data) {
    if (data != null) {
      state.newsInfo = data;
    } else {
      state.newsInfo = null;
    }
  },
  getNewsDetailsData(state, data) {
    if (data != null) {
      state.newsDetails = data.data;
    } else {
      state.newsDetails = null;
    }
  },
};

const actions = {
  async getNews({ commit }, id) {
    await http
      .get("/settings/news/show/" + id)
      .then((response) => {
        if (response) {
          commit("getNewsDetailsData", response.data);
        } else {
          commit("getNewsDetailsData", null);
        }
      })
      .catch((error) => {
        commit("getNewsDetailsData", null);
      });
  },
  async saveNews({ commit }, data) {
    await http
      .post("/settings/news/create", data)
      .then((response) => {
        if (response) {
          commit("saveNewsData", response.data.data);
        } else {
          commit("saveNewsData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async updateNews({ commit }, data) {
    await http
      .post("/settings/news/update/" + data.id, data)
      .then((response) => {
        if (response) {
          commit("saveNewsData", response.data.data);
        } else {
          commit("saveNewsData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
};
