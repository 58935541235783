import router from "@/router";
import { http } from "@/config/http";
import _ from "lodash";

const namespaced = true;

const getDefaultState = () => {
  return {
    roleData: {},
    sysRoles: {},
    Permissionsdata: {},
  };
};

const state = getDefaultState();

const getters = {};

const mutations = {
  setRoleData(state, data) {
    if (data != null) {
      state.roleData = data.data;
      state.roleData.status = data.meta.status;
      state.roleData.status_message = data.meta.status_message;
    }
  },

  SetPermissionData(state, data) {
    //  console.log(data);
    state.Permissionsdata = data;
  },

  resetRoleData(state) {
    state.roleData.role_id = "";
    state.roleData.role_name = "";
    state.roleData.permissions = "";
  },

  resePermissionData(state, data) {
    console.log(data);
    // state.roleData.role_id = data.id;
    // state.roleData.role_name = data.name;
    state.roleData.permissions = "";
  },

  SetSystemRoles(state, data) {
    state.sysRoles = data;
  },
};

const actions = {
  async getRoleData({ commit }, data) {
    let role_id = data;
    await http
      .get("authorization/role/show/" + role_id, null)
      .then((response) => {
        commit("setRoleData", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async createRole({ commit }, data) {
    await http
      .post("authorization/role/store", {
        name: data.name,
        permissions: data.permissions,
      })
      .then((response) => {
        console.log(response);
        if (response.data.meta.status == true) {
          commit("setRoleData", response.data);
        }
      })
      .catch((error) => {});
  },

  async updateRoleData({ commit }, data) {
    let role_id = data.id;
    await http
      .post("authorization/role/update/" + role_id, {
        name: data.name,
        permissions: data.permissions,
      })
      .then((response) => {
        if (response.data.meta.status == true) {
          commit("setRoleData", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getSystemRoles({ commit }) {
    await http.get("authorization/role/list", null).then((response) => {
      commit("SetSystemRoles", response.data.data);
    });
  },
  async getPermissionsData({ commit }) {
    await http
      .get("authorization/permission/list?per_page=100", null)
      .then((response) => {
        commit("SetPermissionData", response.data.data);
      });
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
