<template>
  <router-view></router-view>
</template>
<script>
const regeneratorRuntime = require("regenerator-runtime");
export default {
  name: 'App'
}
</script>

<style lang="scss">  
  @import 'assets/scss/style';
</style>
