import router from "@/router";
import { http } from "../../config/http";

import _ from "lodash";

const namespaced = true;

const getDefaultState = () => {
  return {
    branchInfo: [],
    branchDetails: [],
  };
};

const state = getDefaultState();

const mutations = {
  saveBranchData(state, data) {
    if (data != null) {
      state.branchInfo = data;
    } else {
      state.branchInfo = null;
    }
  },
  getBranchDetailsData(state, data) {
    if (data != null) {
      state.branchDetails = data.data;
    } else {
      state.branchDetails = null;
    }
  },
};

const actions = {
  async getBranch({ commit }, id) {
    await http
      .get("/settings/branch/show/" + id)
      .then((response) => {
        if (response) {
          commit("getBranchDetailsData", response.data);
        } else {
          commit("getBranchDetailsData", null);
        }
      })
      .catch((error) => {
        commit("getBranchDetailsData", null);
      });
  },
  async saveBranch({ commit }, data) {
    await http
      .post("/settings/branch/create", data)
      .then((response) => {
        if (response) {
          commit("saveBranchData", response.data.data);
        } else {
          commit("saveBranchData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async updateBranch({ commit }, data) {
    await http
      .post("/settings/branch/update/" + data.id, data)
      .then((response) => {
        if (response) {
          commit("saveBranchData", response.data.data);
        } else {
          commit("saveBranchData", null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default {
  namespaced,
  state,
  actions,
  mutations,
};
