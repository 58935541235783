import router from "@/router";
import { http } from "@/config/http";
import _ from "lodash";

const namespaced = true;

const getDefaultState = () => {
  return {
    userData: {
      first_name: "",
      last_name: "",
      email: "",
      user_id: "",
      roles: [],
      role: [],
      active: true,
      first_name_list: [],
    },
  };
};

const state = getDefaultState();

const getters = {};

const mutations = {
  updateUserData(state, data) {
    if (data != null) {
      state.userData = data.data;
      state.userData.status = data.meta.status;
      state.userData.status_message = data.meta.status_message;
    }
  },
  updateAllUserData(state, data) {
    if (data != null) {
      state.userData = data.data;
      state.userData.status = data.meta.status;
      state.userData.first_name_list = data;
    }
  },
};

const actions = {
  async createUser({ commit }, data) {
    await http
      .post("authorization/registration/store", data)
      .then((response) => {
        commit("updateAllUserData", response.data);
      })
      .catch((error) => {
        commit("updateUserData", null);
      });
  },

  async updateUser({ commit }, data, userId = null) {
    await http
      .post("authorization/registration/update/" + data.id + "", data)
      .then((response) => {
        commit("updateAllUserData", response.data);
      })
      .catch((error) => {
        commit("updateUserData", null);
      });
  },
  async getUserData({ commit }, userId) {
    await http
      .get("authorization/registration/show/" + userId + "", {}, {})
      .then((response) => {
        if (response) {
          commit("updateUserData", response.data);
        } else {
          commit("updateUserData", null);
        }
      })
      .catch((error) => {
        commit("updateUserData", null);
      });
  },
  async getAllUserData({ commit }) {
    await http
      .get("v1/users/userslist", null)
      .then((response) => {
        if (response) {
          commit("updateAllUserData", response.data);
        } else {
          commit("updateUserData", null);
        }
      })
      .catch((error) => {
        commit("updateUserData", null);
      });
  },
  async changePassword({ commit }, data) {
    await http
      .post("authorization/registration/change-password", data)
      .then((response) => {
        commit("updateAllUserData", response.data);
      })
      .catch((error) => {
        commit("updateUserData", null);
      });
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
